import { disableDataSourceTypes, enableDataSourceTypes } from '@/api/pmdatasourcetype';

import store from '@/store/store';

const disableDataSourceType = {
	id: 'disableDataSourceType',
	selectionType: 'multiple',
	label: 'pmdatasourcetype.actions.disableDataSourceType',
	functionality: 'UPDATE_PMDATASOURCETYPE',
	checkAvailability: function (datasourcetype) {
		return datasourcetype && datasourcetype.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmdatasourcetype.actions.disableDataSourceType');
		let allowDisable = true;
		var body = [];
		for (let i = 0; i < registries.length; i++) {
			let deviceType = store.getters.getDatasourcetype.find(
				(datasourcetype) => datasourcetype.pmdatasourcetypeid == registries[i].pmdatasourcetypeid
			);
			if (deviceType && deviceType.activestations > 0) {
				allowDisable = false;
			} else {
				body.push({
					pmdatasourcetypeid: registries[i].pmdatasourcetypeid
				});
			}
		}
		//const data = await disableDataSourceTypes(body);
		let data;
		// eslint-disable-next-line no-unused-vars
		let res2;
		if (allowDisable) {
			data = await disableDataSourceTypes(body);
		} else {
			data = { error: true };
		}

		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = allowDisable ? this.$puiI18n.t('puiaction.notifyError') : this.$t('pmdatasourcetype.actions.disableError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableDataSourceType = {
	id: 'enableDataSourceType',
	selectionType: 'multiple',
	label: 'pmdatasourcetype.actions.enableDataSourceType',
	functionality: 'UPDATE_PMDATASOURCETYPE',
	checkAvailability: function (datasourcetype) {
		return datasourcetype && datasourcetype.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmdatasourcetype.actions.enableDataSourceType');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmdatasourcetypeid: registries[i].pmdatasourcetypeid
			});
		}

		//const data = await enableDataSourceTypes(body);

		// eslint-disable-next-line no-unused-vars
		let data = await enableDataSourceTypes(body);

		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

export default {
	actions: [disableDataSourceType, enableDataSourceType]
};
