<template>
	<pui-datatable
		v-if="isSuperAdmin"
		:modelName="model"
		:modelColumnDefs="columnDefs"
		:externalFilter="externalFilter"
		:showDeleteBtn="true"
		:actions="actions"
		:masterDetailColumnVisibles="masterDetailColumnVisibles"
	></pui-datatable>
	<pui-datatable
		v-else
		:modelName="model"
		:modelColumnDefs="columnDefs"
		:externalFilter="externalFilter"
		:showDeleteBtn="true"
		:actions="actions"
	></pui-datatable>
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixin';
import DataSourceTypeActions from './PmDataSourceTypeActions';

import { getDatasourcetype, isSuperAdmin, search } from '@/api/common';

export default {
	mixins: [PuiGridDetailMixin],
	name: 'pmdatasourcetypegrid',
	data() {
		return {
			model: 'pmdatasourcetype',
			actions: DataSourceTypeActions.actions,
			masterDetailColumnVisibles: {
				name: true,
				description: true,
				pmdatamodelname: true,
				pmdatamodeldescription: true,
				iconroute: true,
				elasticacronym: true,
				organizationname: true,
				disabled: true
			},
			columnDefs: {
				disabled: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				},
				iconroute: {
					createdCell: (td, cellData) => {
						td.style.padding = '6px 0px 0px 20px';
						td.style.width = '3rem';
						return (td.innerHTML = '<img src="' + this.iconPath(cellData) + '" style="max-width:1.5rem;max-height:1.5rem"></img>');
					}
				}
			},
			isSuperAdmin: false
		};
	},
	computed: {},
	created() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
	},
	mounted() {
		// eslint-disable-next-line no-unused-vars

		if (!this.isSuperAdmin) {
			this.externalFilter = {
				groupOp: 'or',
				rules: [{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }],
				groups: []
			};
		}
		this.updateDatasourcetypeStore();
	},

	beforeDestroy() {
		console.log('BEFORE DESTROY');
		//this.doAsync();
	},
	methods: {
		async doAsync() {
			var start = Date.now(),
				time;
			console.log(0);
			time = await this.doSomethingAsync(100);
			console.log(time - start);
		},

		doSomethingAsync(time) {
			//return this.timeoutPromise(1000);
			return new Promise(function (resolve) {
				setTimeout(function () {
					getDatasourcetype(this.session.language, this.userProperties);
					resolve(Date.now());
				}, time);
			});
		},
		iconPath(route) {
			var appProperties = this.$store.getters.getApplication;
			return appProperties.urlbase + route;
		},
		async updateDatasourcetypeStore() {
			let rulesDtsType = [];
			if (!this.isSuperAdmin) {
				rulesDtsType.push({ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid });
			}
			const filterDatasourcetype = {
				groups: [],
				groupOp: 'and',
				rules: rulesDtsType
			};

			const body = {
				model: 'pmdatasourcetype',
				filter: filterDatasourcetype,
				rows: 100
			};

			//console.log(body);
			//console.log(filterDatasourcetype);

			const { data } = await search(body);
			console.log('datasourcetype data', data.data);
			this.$store.commit('setDatasourcetype', data.data);
		}
	}
};
</script>

<style lang="postcss" scoped></style>
